import { useContext, createContext, useEffect, useState } from "react";
import NodeRenderer from "./NodeRenderer";
import Header from "./Header";
import { mergeNodetoLocaleNoSubNode, slugify } from "./utils/helpers";
import labels from "./labels/index";
import ProgressBar from "./ProgressBar";
import CharlotteModal from "./CharlotteModal";
import SubmitModal from "./SubmitModal";
import MenuModal from "./MenuModal";
// import Carp from "../icons/Carp";

const ManywaysContext = createContext(null);

const ManywaysProvider = ({
  children,
  slug,
  classNamePrefix = "mw",
  mode = "scroll",
  locale = "en",
}) => {
  let [nodes, setNodes] = useState([]);
  let [responseId, setResponseId] = useState(false);
  let [treeConfig, setTreeConfig] = useState({});
  let [currentNodeId, setCurrentNodeId] = useState(false);
  let [responses, setResponses] = useState([]);
  let [isLoading, setIsLoading] = useState(true);
  const [charlotteModalOpen, setCharlotteModalOpen] = useState(false);
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [menuModalOpen, setMenuModalOpen] = useState(false);
  const [textFade, setTextFade] = useState(true);
  const [showResults, setShowResults] = useState(false);
  const [contactPermission, setContactPermission] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [charlotteFormSubmitted, setCharlotteFormSubmitted] = useState(false);
  const [resetTrigger, setResetTrigger] = useState(false);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [resultsFullyLoaded, setResultsFullyLoaded] = useState(false);

  let currentNode =
    setCurrentNodeId !== false ? nodes.find((n) => n.id === currentNodeId) : false;

  // let umamidata = {
  //   website: treeConfig?.analytics_config?.umami_id,
  // };

  const getInitialData = async (props = {}) => {
    const { callback = () => {}, callbackArgs = {} } = props;
    setIsLoading(true);
    await fetch(`https://mw-apiv2-prod.fly.dev/response_sessions/${slug}/begin`)
      .then((response) => response.json())
      .then((data) => {
        setNodes([data?.current_node]);
        setCurrentNodeId(data?.node_id);
        setResponseId(data?.id);
        setTreeConfig(data?.revision);
        setIsLoading(false);
        callback({ data, nodes: [data?.current_node], callbackArgs });

        setTimeout(() => {
          // hack for tabs
          document
            .querySelectorAll(".mw-node-find-by-form .field-radio-group label")
            .forEach((el) => {
              el.addEventListener("click", function () {
                if (!!el.closest(".is-current-node-true")) {
                  console.log("no response. should be forwarded");
                  return false;
                } else {
                  console.log("response exists. should be restarted in queue");
                  window.manyways.restartInQueue([{ result: el.innerText }]);
                }
              });
            });
        }, 400);
      });
  };

  const goForward = async ({ formData }) => {
    if (!!isLoading) {
      console.log("is loading aborting go forward");
      return false;
    }

    setTextFade(true);
    setIsLoading(true);
    let theResponse = {
      // node_id: currentNode?.id,
      node_id: currentNodeId,
      response: formData,
    };

    // window.umami.track({
    //   title: currentNode?.title,
    //   data: {
    //     nodeId: currentNode?.id,
    //     response: JSON.stringify(formData),
    //   },
    //   hostname: window.location.hostname,
    //   language: navigator.language,
    //   referrer: document.referrer,
    //   screen: `${window.screen.width}x${window.screen.height}`,
    //   url: window.location.pathname,
    //   website: treeConfig?.analytics_config?.umami_id,
    //   name: "Node Response",
    // });

    await fetch(`https://mw-apiv2-prod.fly.dev/response_sessions/${responseId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(theResponse),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!!window.umami?.track) {
          window.umami.track((props) => ({
            ...props,
            url: `/${slugify(data.title)}`,
            title: data.title,
          }));
        }

        let final_json = data?.form_schema;
        try {
          final_json = data?.content;
        } catch (e) {
          console.log(e);
        }
        // console.log(final_json);
        setNodes([...nodes, { ...data, form_schema: final_json }]);
        setResponses([...responses, { node_id: currentNode?.id, ...theResponse }]);
        setCurrentNodeId(data?.id);
        setIsLoading(false);
      });
  };

  const goBack = async function () {
    let currentNodeIndexInResponses = responses.findIndex(
      (r) => r.node_id === currentNodeId
    );
    currentNodeIndexInResponses =
      currentNodeIndexInResponses > -1 ? currentNodeIndexInResponses : responses.length;
    let theLastResponse = responses[currentNodeIndexInResponses - 1];
    if (
      !!theLastResponse &&
      !!theLastResponse?.node_id &&
      responses?.[0].node_id === theLastResponse?.node_id
    ) {
      restart();
    } else if (!!theLastResponse && !!theLastResponse?.node_id) {
      let _nodes = nodes.filter((n, idx) => idx < nodes.length - 1);
      setNodes(_nodes);
      setCurrentNodeId(theLastResponse?.node_id);
    } else {
      console.log("cannot go back");
      console.log("the last response", theLastResponse);
    }
  };

  const setQueueData = async ({ data, nodes, callbackArgs }) => {
    await fetch(`https://mw-apiv2-prod.fly.dev/response_sessions/${data?.id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ responses: callbackArgs }),
    })
      .then((response) => response.json())
      .then((d) => {
        console.log("setQueueData", d, data, callbackArgs, nodes);
        let final_json = d?.form_schema;
        try {
          final_json = d?.content;
        } catch (e) {
          console.log(e);
        }
        setNodes([...nodes, { ...d, form_schema: final_json }]);
        setResponses([...responses.map((r, i) => ({ ...r, node_id: i.id }))]);
        setCurrentNodeId(d?.id);
        setIsLoading(false);
      });
  };

  const restartInQueue = async (nodeData) => {
    setNodes([]);
    setResponses([]);
    setCurrentNodeId(false);
    setTreeConfig({});
    setIsLoading(true);
    await getInitialData({ callback: setQueueData, callbackArgs: nodeData });
  };

  const restart = async () => {
    setNodes([]);
    setResponses([]);
    setCurrentNodeId(false);
    setTreeConfig({});
    setIsLoading(true);
    await getInitialData();
  };

  const setUpUmami = () => {
    window.umami = window.umami || {};
    if (!!treeConfig?.analytics_config?.umami_id) {
      var el = document.createElement("script");
      el.setAttribute("src", "https://umami-analytics-nine-xi.vercel.app/script.js");
      el.setAttribute("data-website-id", treeConfig?.analytics_config?.umami_id);
      document.body.appendChild(el);
    }
  };

  useEffect(() => {
    window.manyways.restartInQueue = restartInQueue;
    window.manyways.restart = restart;
    getInitialData();
  }, [slug]);

  useEffect(() => {
    setUpUmami();
  }, [treeConfig]);

  let getResponseByNodeID,
    journeyNodes,
    // locale,
    setLocale,
    shareJourney,
    copyLink;

  const [swiperControls, setSwiperControls] = useState({
    slideNext: () => {},
    slidePrev: () => {},
  });

  const updateSwiperControls = (controls) => {
    setSwiperControls(controls);
  };

  const handleMenuToggleModal = () => {
    if (menuModalOpen) {
      setTimeout(() => {
        setMenuModalOpen(false);
      }, 100);
    } else {
      setMenuModalOpen(true);
    }
  };

  const triggerReset = () => {
    setResetTrigger(true);
  };

  const clearResetTrigger = () => {
    setResetTrigger(false);
  };

  const isFirstNode = currentNode?.title === "Start" ? true : false;

  return (
    <ManywaysContext.Provider
      value={{
        nodes: nodes.map((n) => mergeNodetoLocaleNoSubNode(n, locale)),
        currentNodeId,
        currentNode,
        goBack,
        responses,
        goForward,
        getResponseByNodeID,
        treeConfig,
        journeyNodes,
        locale,
        labels: labels[locale],
        setLocale,
        shareJourney,
        copyLink,
        classNamePrefix,
        mode,
        isLoading,
        textFade,
        setTextFade,
        charlotteModalOpen,
        setCharlotteModalOpen,
        swiperControls,
        updateSwiperControls,
        submitModalOpen,
        setSubmitModalOpen,
        showResults,
        setShowResults,
        contactPermission,
        setContactPermission,
        marketingConsent,
        setMarketingConsent,
        charlotteFormSubmitted,
        setCharlotteFormSubmitted,
        triggerReset,
        clearResetTrigger,
        resetTrigger,
        isFadingOut,
        setIsFadingOut,
        resultsLoading,
        setResultsLoading,
        resultsFullyLoaded,
        setResultsFullyLoaded,
      }}>
      <div
        className={`${classNamePrefix}-${slug} ${classNamePrefix}-${mode} ${classNamePrefix}-journey-container has-header-${!!treeConfig
          ?.run_mode?.logo} ${nodes.map((n) => `mw-${slugify(n.title)}`).join(" ")} ${
          charlotteModalOpen || submitModalOpen ? "locked-scroll" : ""
        }`}>
        {mode === "scroll" && treeConfig?.run_mode?.ui_variables?.backgroundImage ? (
          <div
            className={`${classNamePrefix}-global-bg-image`}
            style={{
              backgroundImage: `url(${treeConfig?.run_mode?.ui_variables?.backgroundImage})`,
            }}></div>
        ) : null}
        <Header
          charlotteModalOpen={charlotteModalOpen}
          setCharlotteModalOpen={setCharlotteModalOpen}
          menuModalOpen={menuModalOpen}
          setMenuModalOpen={setMenuModalOpen}
          handleMenuToggleModal={handleMenuToggleModal}
          // shareJourney={shareJourney}
          // copyLink={copyLink}
        />
        <MenuModal
          showResults={showResults}
          setShowResults={setShowResults}
          menuModalOpen={menuModalOpen}
          handleMenuToggleModal={handleMenuToggleModal}
          setMenuModalOpen={setMenuModalOpen}
          // shareJourney={shareJourney}
          // copyLink={copyLink}
        />
        <CharlotteModal
          setSubmitModalOpen={setSubmitModalOpen}
          charlotteModalOpen={charlotteModalOpen}
          setCharlotteModalOpen={setCharlotteModalOpen}
          contactPermission={contactPermission}
          setContactPermission={setContactPermission}
          marketingConsent={marketingConsent}
          setMarketingConsent={setMarketingConsent}
          charlotteFormSubmitted={charlotteFormSubmitted}
          setCharlotteFormSubmitted={setCharlotteFormSubmitted}
          currentNode={currentNode}
        />

        <SubmitModal
          submitModalOpen={submitModalOpen}
          setSubmitModalOpen={setSubmitModalOpen}
          currentNode={currentNode}
          setCharlotteFormSubmitted={setCharlotteFormSubmitted}
          setCharlotteModalOpen={setCharlotteModalOpen}
          setMarketingConsent={setMarketingConsent}
          setContactPermission={setContactPermission}
        />
        <NodeRenderer />
        {children}
        {isFirstNode && (
          <div className="carp-container">
            <div className="carp-holder">
              <p>in association with</p>
              <img
                src={`${process.env.PUBLIC_URL}/common/${
                  process.env.REACT_APP_CUSTOM_IMAGE_NAME || "/common/cpc-logo.png"
                }`}
                className={`${
                  process.env.REACT_APP_CUSTOM_IMAGE_STYLE || "member-association-logo"
                }`}
                alt="Corporate Logo"
              />
            </div>
          </div>
        )}
        <ProgressBar initialCurrentId={currentNode?.id} />
      </div>
    </ManywaysContext.Provider>
  );
};

const useManyways = () => useContext(ManywaysContext);

export { ManywaysProvider, useManyways };
