import { ChangeEvent, FocusEvent, SyntheticEvent, useCallback } from "react";
import {
  ariaDescribedByIds,
  enumOptionsIndexForValue,
  enumOptionsValueForIndex,
} from "@rjsf/utils";

function getValue(event, multiple) {
  if (multiple) {
    return Array.from(event.target.options)
      .slice()
      .filter((o) => o.selected)
      .map((o) => o.value);
  }
  return event.target.value;
}

/** The `SelectWidget` is a widget for rendering dropdowns.
 *  It is typically used with string properties constrained with enum options.
 *
 * @param props - The `WidgetProps` for this component
 */
function ManywaysSelectWidget({
  schema,
  id,
  options,
  value,
  required,
  disabled,
  readonly,
  multiple = false,
  autofocus = false,
  onChange,
  onBlur,
  onFocus,
  placeholder,
}) {
  const { enumOptions, enumDisabled, emptyValue: optEmptyVal } = options;
  const emptyValue = multiple ? [] : "";

  const handleFocus = useCallback(
    (event) => {
      const newValue = getValue(event, multiple);
      return onFocus(id, enumOptionsValueForIndex(newValue, enumOptions, optEmptyVal));
    },
    [onFocus, id, schema, multiple, options]
  );

  const handleBlur = useCallback(
    (event) => {
      const newValue = getValue(event, multiple);
      return onBlur(id, enumOptionsValueForIndex(newValue, enumOptions, optEmptyVal));
    },
    [onBlur, id, schema, multiple, options]
  );

  const handleChange = useCallback(
    (event) => {
      const newValue = getValue(event, multiple);
      return onChange(enumOptionsValueForIndex(newValue, enumOptions, optEmptyVal));
    },
    [onChange, schema, multiple, options]
  );

  const selectedIndexes = enumOptionsIndexForValue(value, enumOptions, multiple);

  return !multiple ? (
    <div class="select-wrapper">
      <select
        id={id}
        name={id}
        multiple={multiple}
        className="form-control"
        value={typeof selectedIndexes === "undefined" ? emptyValue : selectedIndexes}
        required={required}
        disabled={disabled || readonly}
        autoFocus={autofocus}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        aria-describedby={ariaDescribedByIds(id)}>
        {!multiple && schema.default === undefined && (
          <option value="">{placeholder}</option>
        )}
        {Array.isArray(enumOptions) &&
          enumOptions.map(({ value, label }, i) => {
            const disabled = enumDisabled && enumDisabled.indexOf(value) !== -1;
            return (
              <option key={i} value={String(i)} disabled={disabled}>
                {label}
              </option>
            );
          })}
      </select>
    </div>
  ) : (
    <div className="multi-select-grid">
      {Array.isArray(enumOptions) &&
        enumOptions.map((props, i) => {
          const { label } = props;
          let _val = props.value;
          const disabled = enumDisabled && enumDisabled.indexOf(_val) !== -1;
          const unchecked = value.indexOf(_val) === -1;
          const marginRightStyle = (i + 1) % 3 === 0 ? { marginRight: "0px" } : {};

          const buttonStyle = !unchecked
            ? {
                backgroundColor: "white",
                color: "black",
                ...marginRightStyle,
              }
            : marginRightStyle;
          return (
            <button
              key={i}
              onClick={(e) => {
                e.preventDefault();
                let newValue = [...value];
                const index = newValue.indexOf(_val);
                if (unchecked) {
                  newValue.push(_val);
                } else {
                  newValue.splice(index, 1);
                }
                onChange(newValue);
              }}
              className={
                enumOptions.length > 10
                  ? `multi-select-button`
                  : "multi-select-button-full"
              }
              style={buttonStyle}
              disabled={disabled}>
              {label}
            </button>
          );
        })}
    </div>
  );
}

export default ManywaysSelectWidget;
