const EPTResultsLoader = ({ isFadingOut }) => {
  return (
    <div className={`loading-screen ${isFadingOut ? "fade-out" : ""}`}>
      <div className="loading-screen-outer">
        <div className="loading-black-background"></div>
        <div className="loading-container">
          <div className="loader"></div>
          <div className="loading-header-container">
            <h4 className="loading-header">
              Hold on tight... we're finding the <span className="italic">best</span>{" "}
              trips for you.
            </h4>
          </div>
          <p className="loading-desc">
            Did you know? The average cruise ship sails the equivalent of three times
            around the world each year.
          </p>
        </div>
      </div>
    </div>
  );
};

export default EPTResultsLoader;
