import React, { useState, useRef } from "react";
import CruiseIq from "../icons/CruiseIq.js";
import Hamburger from "../icons/Hamburger.js";
import Speaker from "../icons/Speaker.js";
import SpeakerMuted from "../icons/SpeakerMuted.js";
import charlotte from "../pictures/charlotte.png";
import backgroundAudio from "../assets/audio/waves.mp3";
import CloseLarge from "../icons/CloseLarge.js";
import PhoneWhite from "../icons/PhoneWhite.js";

const Header = ({
  // shareJourney,
  // copyLink,
  setMenuModalOpen,
  charlotteModalOpen,
  setCharlotteModalOpen,
  menuModalOpen,
  handleMenuToggleModal,
}) => {
  const [isMuted, setIsMuted] = useState(true);
  const audioRef = useRef(null);

  const toggleSound = (e) => {
    e.stopPropagation();
    setIsMuted(!isMuted);

    const audioElement = audioRef.current;
    if (audioElement) {
      if (!isMuted) {
        audioElement.muted = false;
        audioElement.pause();
      } else {
        audioElement.muted = true;
        audioElement.play().catch((error) => console.error("Playback failed:", error));
      }
    }
  };

  const handleToggleModal = () => {
    if (charlotteModalOpen) {
      setTimeout(() => {
        setCharlotteModalOpen(false);
      }, 300);
    } else {
      setCharlotteModalOpen(true);
    }
  };

  return (
    <header className="universal-wrapper top-layer">
      <audio
        ref={audioRef}
        id="backgroundAudio"
        src={backgroundAudio}
        autoPlay
        loop
        muted={isMuted}
        // onCanPlayThrough={(e) => console.log("Audio can play through")}
        // controls
      ></audio>
      <div className="header-container">
        <div className="header-item">
          <button className="hamburger-button" onClick={handleMenuToggleModal}>
            {menuModalOpen ? <CloseLarge className="close-icon" /> : <Hamburger />}
          </button>
          {/* <div className="mobile-cruise">
            <CruiseIq />
          </div> */}
        </div>
        <div className="desktop-cruise">
          <CruiseIq />
        </div>

        <div className="header-item">
          <button className="char-button" onClick={handleToggleModal}>
            <p className="char-button-text">Speak to agent</p>
            <img className="char-photo-header" src={charlotte} alt="Charlotte" />
            <PhoneWhite />
          </button>
          <button className="speaker-button" onClick={toggleSound}>
            {isMuted ? <SpeakerMuted /> : <Speaker />}
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
