import * as React from "react";
const Phone = (props) => (
  <div className="phone-container">
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        fill="#FFFFFF"
        d="m14.398 9.404-2.944-1.32-.008-.003a1 1 0 0 0-.995.122L8.929 9.5c-.963-.468-1.958-1.456-2.426-2.407L7.8 5.55a1 1 0 0 0 .118-.99v-.007l-1.323-2.95a1 1 0 0 0-1.038-.595A3.516 3.516 0 0 0 2.5 4.5c0 4.963 4.038 9 9 9a3.516 3.516 0 0 0 3.492-3.057 1 1 0 0 0-.594-1.04ZM11.5 12.5a8.009 8.009 0 0 1-8-8A2.512 2.512 0 0 1 5.68 2v.007l1.312 2.938L5.7 6.491a1 1 0 0 0-.098 1.03c.566 1.158 1.733 2.316 2.904 2.881a1 1 0 0 0 1.03-.106L11.056 9l2.937 1.316H14a2.513 2.513 0 0 1-2.5 2.184Z"
      />
    </svg>
  </div>
);
export default Phone;
