const MenuModal = ({ showResults, setShowResults, menuModalOpen, setMenuModalOpen }) => {
  return (
    <div className={`menu-modal-container ${menuModalOpen ? "show top-layer" : ""}`}>
      <ul>
        <li
          onClick={(e) => {
            window.manyways.restart();
            if (menuModalOpen) {
              setTimeout(() => {
                setMenuModalOpen(false);
              }, 100);
            }
            if (showResults) {
              setShowResults(false);
            }
          }}>
          Restart this guide
        </li>
        <li
          onClick={(e) => {
            window.manyways.share();
          }}>
          Share this guide
        </li>
        <li className="manyways">
          <a
            className="manyways"
            href="https://www.manyways.io/"
            target="_blank"
            rel="noopener noreferrer">
            Powered by Manyways
          </a>
        </li>
      </ul>
    </div>
  );
};

export default MenuModal;
