import * as React from "react";

function DoubleArrow(props) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="rgba(255, 255, 255, 0.5)"
      className="prefix__w-6 prefix__h-6"
      width="1em"
      height="1em"
      {...props}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 5.25l7.5 7.5 7.5-7.5m-15 6l7.5 7.5 7.5-7.5"
      />
    </svg>
  );
}

const MemoDoubleArrow = React.memo(DoubleArrow);
export default MemoDoubleArrow;
