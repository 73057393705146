import { useEffect, useState, useRef } from "react";
import _results from "./../../data-samples/results.json";
import { Swiper, SwiperSlide } from "swiper/react";
import { useManyways } from "../ManywaysContext";
import { EffectCreative, Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import ContactForm from "../ContactForm";
import EPTResultsFooter from "./EPTResultsFooter";
import MemoDoubleArrow from "../../icons/DoubleArrow";

const Price = ({ priceRange }) => {
  const options = {
    style: "currency",
    currency: "CAD",
  };

  const formatPrice = (price) => {
    return price.toLocaleString("en-CA", options);
  };

  const discountedPrice = priceRange
    .split(" - ")
    .map((p) => parseFloat(p))
    .map((p) => p - (p * 10) / 100)
    .map(formatPrice)
    .join(" - ");

  const formattedPriceRange = priceRange
    .split(" - ")
    .map((p) => parseFloat(p))
    .map(formatPrice)
    .join(" - ");

  const [discountApplied, setDiscountApplied] = useState(false);

  return (
    <div>
      <div
        className={`fullprice ${discountApplied ? "applied" : ""}`}
        style={{
          textDecoration: discountApplied ? "line-through" : "none",
          // wordWrap: "break-word",
          // overflowWrap: "break-word",
        }}>
        {formattedPriceRange}
      </div>
      {discountApplied && <div className="discountedprice">{discountedPrice}</div>}
      <button
        className={`apply-price ${discountApplied ? "applied" : ""}`}
        onClick={() => {
          setDiscountApplied(true);
        }}>
        Apply discount
      </button>
    </div>
  );
};

const ImageWithSkeleton = ({ src, alt, className }) => {
  // const [loaded, setLoaded] = useState(false);

  return (
    <img
      src={src}
      alt={alt}
      className={`${className}`}
      // className={`${className} ${!loaded ? "skeleton" : ""} skeleton`}
      // onLoad={() => setLoaded(true)}
      // onError={() => setLoaded(true)}
    />
  );
};

const SingleEPTResult = ({
  featuredImage,
  id,
  name,
  countries,
  ship,
  smallOverview,
  results = [],
  packages,
  swiper,
  currentNode,
  // years,
  // waterways,
  // cruiseType,
  // description,
  // overviewText,
  // largeOverview,
  // regions,
}) => {
  const thePackage = packages[0] || { price: {} };
  const displayCount = 5;
  const totalCountries = countries.length;
  const extraCountries =
    totalCountries > displayCount ? totalCountries - displayCount : 0;
  const {
    price: { priceRange, prices },
    itineraryDays,
    duration,
    guidedTours,
    itineraryImages,
    mainEnlargedMap,
    // currency,
    // discount,
    // discountCurrency,
    // itineraryHeroImage,
  } = thePackage;

  const {
    setCharlotteModalOpen,
    setSubmitModalOpen,
    contactPermission,
    setContactPermission,
    marketingConsent,
    setMarketingConsent,
    setCharlotteFormSubmitted,
    // resultsFullyLoaded,
  } = useManyways();

  // useEffect(() => {
  //   console.log(
  //     "Country keys:",
  //     countries.slice(0, displayCount).map((c, idx) => `${c}-${idx}`)
  //   );
  // }, [countries]);

  // useEffect(() => {
  //   console.log(
  //     "Itinerary Days keys:",
  //     packages[0]?.itineraryDays.map(({ title }, idx) => `${title}-${idx}-${title}`)
  //   );
  // }, [packages]);

  // useEffect(() => {
  //   console.log(
  //     "State Rooms keys:",
  //     ship.stateRooms.map((room, idx) => `${room.name}-${idx}`)
  //   );
  // }, [ship.stateRooms]);

  // useEffect(() => {
  //   console.log(
  //     "Itinerary Images keys:",
  //     packages[0]?.itineraryImages.map((img, idx) => `${img}-${idx}`)
  //   );
  // }, [packages]);

  return (
    <div
      className="single-result results-wrapper-desktop"
      style={{ background: "transparent" }}>
      <div className="single-results-background-holder">
        <div
          className="single-result-background"
          style={{
            color: `white`,
          }}>
          <ImageWithSkeleton src={featuredImage} className="result-image" />

          <div className="inner-bg">
            <div>
              <div className="inner-bg-content">
                <h2>{name}</h2>

                <div className="inner-bg-grid">
                  <div className="overview-content hide-on-mobile">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: smallOverview,
                      }}></div>
                    <div className="big-button-container">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setCharlotteModalOpen(true);
                        }}
                        className="big-button">
                        Let's sail!
                      </a>
                      <p>scroll down to learn more</p>
                      <MemoDoubleArrow />
                    </div>
                  </div>
                  <div className="country-day">
                    <h4 className="ept-subtitle">Countries</h4>
                    <div className="countries">
                      {countries.slice(0, displayCount).map((c, idx) => (
                        <p key={`${c}-${idx}`}>{c}</p>
                      ))}
                      {extraCountries > 0 && (
                        <p className="more-text">{extraCountries} more</p>
                      )}
                    </div>
                    <div className="duration mobile-show">
                      <h4 className="ept-subtitle">Days</h4>
                      <p>{duration}</p>
                    </div>
                  </div>
                  <div className="country-day mobile-hide">
                    <div className="duration">
                      <h4 className="ept-subtitle">Days</h4>
                      <p>{duration}</p>
                    </div>
                  </div>
                  <div className="price">
                    <h4 className="ept-subtitle">Member Price</h4>
                    <Price priceRange={priceRange} />
                  </div>
                </div>
                <div className="full-width">
                  <p className="activities">
                    <span className="strong">Activities:</span> Take part in any of the{" "}
                    <span className="strong">{guidedTours}</span> guided tours offered.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="single-background-spacer"></div>
      </div>

      <div className="inner-content-container">
        <div
          className="overview-content-mobile hide-on-tablet"
          dangerouslySetInnerHTML={{
            __html: smallOverview,
          }}></div>
        <a className="big-button hide-on-tablet">Let's sail!</a>
      </div>

      <div className="image-side-scene">
        <div className="image-side">
          <ImageWithSkeleton src={ship?.heroImage} alt="Main hero image" />
        </div>
      </div>
      <div className="inner-content-container">
        <h2>Itinerary & Excursions</h2>
        <div className="iternary-and-images">
          <div className="iternary">
            {itineraryDays.map(
              (
                {
                  number,
                  title,
                  destinationDescription,
                  // image,
                  // destinationName,
                  // description,
                },
                idx
              ) => {
                return (
                  <div className="iter-item" key={`${title}-${number}-${idx}`}>
                    <div className="iter-item-flex">
                      <div className="item-inner">
                        <h4 className="day">DAY {number}</h4>
                      </div>
                      <div className="item-inner">
                        <h4 className="title">{title}</h4>
                      </div>
                    </div>

                    <div
                      className="day"
                      dangerouslySetInnerHTML={{
                        __html: destinationDescription,
                      }}></div>
                  </div>
                );
              }
            )}
            <div className="enlarged-map">
              {mainEnlargedMap && (
                <ImageWithSkeleton src={mainEnlargedMap} alt="Map of the routes" />
              )}
            </div>
            <div className="vessel-info">
              <div className="vessel-grid">
                <div className="viking-logo">
                  <img src="/viking.png" alt="Viking logo" />
                </div>
                <div className="vessel-content">
                  <div>
                    <h4>Vessel</h4>
                    <p>{ship.name}</p>
                  </div>
                  <div className="staterooms-holder-mobile">
                    <h4>Staterooms</h4>
                    {ship.stateRooms.map((room, idx) => {
                      return (
                        <div
                          key={`${room.name}-${idx}`}
                          dangerouslySetInnerHTML={{ __html: room.name }}></div>
                      );
                    })}
                  </div>
                </div>
                <div className="staterooms-holder">
                  <h4>Staterooms</h4>
                  {ship.stateRooms.map((room, idx) => {
                    return (
                      <div
                        key={`${room.name}-${idx}`}
                        dangerouslySetInnerHTML={{ __html: room.name }}></div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="iternary-images">
            {itineraryImages.map((img, idx) => {
              return (
                <ImageWithSkeleton key={`${img}-${idx}`} src={img} alt="The itinerary" />
              );
            })}
          </div>
        </div>
      </div>
      <div className="image-and-form">
        <div className="image-final-scene">
          <ImageWithSkeleton src={featuredImage} alt="Map showing the cruise route" />
        </div>
        <div className="results-char inner-content-container charlotte-modal-content show">
          <h2>Book this trip at a member-only price</h2>
          <p>
            Turn this travel experience into reality; please provide your phone number or
            email, and our luxury travel specialist will help you to reserve a spot on the
            perfect journey.
          </p>
          <ContactForm
            resultsPage={true}
            setSubmitModalOpen={setSubmitModalOpen}
            contactPermission={contactPermission}
            setContactPermission={setContactPermission}
            marketingConsent={marketingConsent}
            setMarketingConsent={setMarketingConsent}
            setCharlotteFormSubmitted={setCharlotteFormSubmitted}
            additionalData={currentNode?.form_schema?.properties?.char || {}}
            currReco={name}
          />
        </div>
      </div>

      <div className="grid-topper">
        <h2>Continue exploring your other recommendations</h2>
      </div>

      <div className="otherresults">
        {results.map((r, i) => {
          return (
            r.id !== id &&
            i < 4 && (
              <div
                onClick={(e) => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  setTimeout(() => {
                    swiper.current.slideTo(i);
                  }, 800);
                }}
                className="otheresult">
                <ImageWithSkeleton
                  className="otherresult-img"
                  src={r.featuredImage}
                  alt="Other cruise options"
                />
                <div className="otherresult-title">
                  <div className="fake-button">
                    <p>View trip</p>
                  </div>
                  <h5>{r.name}</h5>
                </div>
              </div>
            )
          );
        })}
      </div>
      <EPTResultsFooter />
    </div>
  );
};

let EPTResults = ({ showResults, schema }) => {
  const {
    updateSwiperControls,
    setResultsLoading,
    setIsFadingOut,
    setResultsFullyLoaded,
    resultsFullyLoaded,
    // isFadingOut,
    currentNode,
  } = useManyways();
  const [results, setResults] = useState([]);
  const [init, setInit] = useState(false);
  const swiperRef = useRef();

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const enableScroll = () => {
    document.body.style.overflow = "";
  };

  const getResults = async () => {
    setResultsLoading(true);
    disableScroll();

    let query = "?";

    // if (!!schema?.properties?.start?.howlong) {
    //   query += `howlong=${schema.properties.start.howlong}&`;
    // }

    // if (!!schema?.properties?.start?.locations) {
    //   const params = new URLSearchParams();
    //   schema.properties.start.locations.split(",").forEach((item) => {
    //     params.append("locations", item);
    //   });
    //   console.log(schema.properties.start);
    //   query += params.toString();
    // }

    // if (
    //   !!schema?.properties?.start?.seasick &&
    //   schema?.properties?.start?.seasick === "Yes, I do"
    // ) {
    //   query += `&river=Yes`;
    // }

    query += new URLSearchParams(schema?.properties?.start).toString();

    const response = await fetch(`https://wayfinder.manyways.io/api/ept${query}`)
      .then((r) => r.json())
      .catch((e) => {
        console.log(e);
        setResultsLoading(false);
        return false;
      });

    if (!response || (Array.isArray(response) && response.length === 0)) {
      setResults(_results);
    } else {
      setResults(response);
    }

    setTimeout(() => {
      setResultsFullyLoaded(true);
      setIsFadingOut(true);
    }, 2000);

    setTimeout(() => {
      setIsFadingOut(false);
      setResultsLoading(false);
      enableScroll();
    }, 5000);
  };

  useEffect(() => {
    getResults();
  }, []);

  useEffect(() => {
    if (swiperRef.current) {
      updateSwiperControls({
        slideNext: () => swiperRef.current.slideNext(),
        slidePrev: () => swiperRef.current.slidePrev(),
      });
    }
  }, [swiperRef.current]);

  console.log(results);

  return (
    <div
      className={`results-holder ready-${init}`}
      style={{
        background: `transparent`,
        height: `100%`,
        minHeight: `100vh`,
        zIndex: 1000,
        position: `relative`,
        opacity: resultsFullyLoaded ? 1 : 0,
      }}>
      <Swiper
        slidesPerView={1}
        // loop={true}
        loop={results.length > 1}
        slidesPerGroup={1}
        allowTouchMove={false}
        autoHeight={true}
        onSwiper={(swiper) => {
          console.log(swiper);
          swiperRef.current = swiper;
        }}
        onAfterInit={(swiper) => {
          setInit(true);
        }}
        onSlideChange={(e) => {
          let idx = e.activeIndex;
          let name = results[idx]?.name;
          window.recos = window.recos || [];
          let recos = [...window.recos];
          recos.push(name);
          window.recos = [...new Set(recos)];
        }}
        speed={900}
        modules={[EffectCreative]}
        effect="creative"
        creativeEffect={{
          prev: {
            // will set `translateZ(-400px)` on previous slides
            translate: [0, 0, -400],
            opacity: 0,
          },
          next: {
            // will set `translateX(100%)` on next slides
            translate: ["100%", 0, 0],
            opacity: 0,
          },
        }}>
        <div className="nextprev-holder-arrows">
          <button
            className="s-nextprev"
            onClick={(e) => {
              console.log(swiperRef.current);
              swiperRef.current.slidePrev();
            }}>
            &larr;
          </button>
          <button
            className="s-nextprev"
            onClick={(e) => {
              swiperRef.current.slideNext();
            }}>
            &rarr;
          </button>
        </div>
        {results.map((r, idx) => (
          <SwiperSlide key={`${r?.name}-${idx}`}>
            <SingleEPTResult
              {...r}
              results={results}
              swiper={swiperRef}
              currentNode={currentNode}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default EPTResults;
